import Icon from "@ant-design/icons";

const Svg = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.4728 6.17455C10.9734 6.05737 11.4859 5.99879 12 6C17.091 6 20 11.8182 20 11.8182C19.5586 12.6441 19.0321 13.4216 18.4291 14.1382M13.5419 13.36C13.3421 13.5744 13.1012 13.7463 12.8336 13.8655C12.566 13.9848 12.2771 14.0489 11.9841 14.0541C11.6912 14.0593 11.4002 14.0054 11.1285 13.8956C10.8568 13.7859 10.61 13.6226 10.4028 13.4154C10.1957 13.2082 10.0323 12.9614 9.9226 12.6897C9.81287 12.4181 9.75898 12.1271 9.76415 11.8341C9.76931 11.5412 9.83344 11.2523 9.95269 10.9846C10.0719 10.717 10.2439 10.4761 10.4582 10.2764M4 4L20 20M16.2132 16.2132C14.97 17.1608 13.563 17.6108 12 17.6364C6.90914 17.6364 4.00005 11.8182 4.00005 11.8182C4.9047 10.1323 6.0875 8.76929 7.60813 7.60813L16.2132 16.2132Z"
        stroke="#8C8C8C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}
export const MyIcon = (props: any) => <Icon component={Svg} {...props} />;