import React from "react";
import {Layout} from "antd";
import {Navigate, useOutlet} from "react-router-dom";
import {useAuth, useTheme, useLang} from "../../hooks";
import {StyledLayout} from "./Layout.styles";
import {ROUTE_PATH} from "../../constants";

export const PublicLayout = (): React.ReactElement => {
  const outlet = useOutlet();
  const {isAuth} = useAuth()
  const {theme} = useTheme()
  const {lang} = useLang()

  if (isAuth) {
    return <Navigate to={ROUTE_PATH.MAIN} replace={true}/>
  }

  return (
    <StyledLayout className={`theme-${theme}`} lang={lang}>
      <Layout.Content>
        {outlet}
      </Layout.Content>
    </StyledLayout>
  )
}