import styled from "styled-components";
import {Col, Row} from "antd";

export const RestorePage = styled(Row)`
  height: 100%;
`

export const LeftCol = styled(Col)`
  background-color: var(--c-bg);
  display: flex;
  justify-content: center;
  
`

export const RightCol = styled(Col)`
  background-color: #fff;
  
  .image{
    position: absolute;
    top: 50%;
    right: 5%;
    transform: translateY(-50%);
  }
`