import styled from "styled-components";
import {Button} from "antd";
import {darken, cssVar} from 'polished'

export const MyButtonStyles = styled(Button)`
  &.ant-btn {
    width: 100%;
    border-radius: 8px;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    padding: 8px 24px;
  }

  &.ant-btn-default {
    &.ant-btn-warning {
      color: var(--c-purple);
      border-color: var(--c-purple);

      &:hover {
        color: ${darken(0.20, <string>cssVar('--c-purple'))};
        border-color: ${darken(0.20, <string>cssVar('--c-purple'))};
      }

      &:disabled {
        border-color: #d9d9d9;
        color: rgba(0, 0, 0, 0.25);
        background-color: rgba(0, 0, 0, 0.04);
        box-shadow: none;
      }
    }

    &.ant-btn-info {
      color: var(--c-link);
      border-color: var(--c-link);

      &:hover {
        color: ${darken(0.20, <string>cssVar('--c-link'))};
        border-color: ${darken(0.20, <string>cssVar('--c-link'))};
      }

      &:disabled {
        border-color: #d9d9d9;
        color: rgba(0, 0, 0, 0.25);
        background-color: rgba(0, 0, 0, 0.04);
        box-shadow: none;
      }
    }

    &.ant-btn-background-ghost {
      &.ant-btn-warning {
        background-color: transparent;

        &:disabled {
          border-color: #d9d9d9;
          color: rgba(0, 0, 0, 0.25);
          background-color: rgba(0, 0, 0, 0.04);
          box-shadow: none;
        }
      }

      &.ant-btn-info {
        background-color: transparent;

        &:disabled {
          border-color: #d9d9d9;
          color: rgba(0, 0, 0, 0.25);
          background-color: rgba(0, 0, 0, 0.04);
          box-shadow: none;
        }
      }
    }
  }

  &.ant-btn-primary {
    &.ant-btn-warning {
      border-color: var(--c-purple);
      background-color: var(--c-purple);

      &:hover {
        background-color: ${darken(0.20, <string>cssVar('--c-purple'))};
        border-color: ${darken(0.20, <string>cssVar('--c-purple'))};
      }

      &:disabled {
        border-color: #d9d9d9;
        color: rgba(0, 0, 0, 0.25);
        background-color: rgba(0, 0, 0, 0.04);
        box-shadow: none;
      }
    }

    &.ant-btn-info {
      border-color: var(--c-link);
      background-color: var(--c-link);

      &:hover {
        background-color: ${darken(0.20, <string>cssVar('--c-link'))};
        border-color: ${darken(0.20, <string>cssVar('--c-link'))};
      }

      &:disabled {
        border-color: #d9d9d9;
        color: rgba(0, 0, 0, 0.25);
        background-color: rgba(0, 0, 0, 0.04);
        box-shadow: none;
      }
    }

    &.ant-btn-background-ghost {
      &.ant-btn-warning {
        color: var(--c-purple);
        background-color: transparent;

        &:hover {
          background-color: transparent;
          color: ${darken(0.20, <string>cssVar('--c-purple'))};
          border-color: ${darken(0.20, <string>cssVar('--c-purple'))};
        }

        &:disabled {
          border-color: #d9d9d9;
          color: rgba(0, 0, 0, 0.25);
          background-color: rgba(0, 0, 0, 0.04);
          box-shadow: none;
        }
      }

      &.ant-btn-info {
        color: var(--c-link);
        background-color: transparent;

        &:hover {
          background-color: transparent;
          color: ${darken(0.20, <string>cssVar('--c-link'))};
          border-color: ${darken(0.20, <string>cssVar('--c-link'))};
        }

        &:disabled {
          border-color: #d9d9d9;
          color: rgba(0, 0, 0, 0.25);
          background-color: rgba(0, 0, 0, 0.04);
          box-shadow: none;
        }
      }
    }
  }

`