import Icon from "@ant-design/icons";

const Svg = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M12.0005 12C14.7619 12 17 9.76085 17 6.99948C17 4.23811 14.7619 2 12.0005 2C9.23917 2 7.00003 4.23811 7.00003 6.99948C6.9907 9.75153 9.2143 11.9907 11.9653 12H12.0005Z" stroke="#8C8C8C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M12.0006 15C7.14585 15 3 15.6968 3 18.4875C3 21.2782 7.11955 22 12.0006 22C16.8553 22 21 21.302 21 18.5125C21 15.7229 16.8816 15 12.0006 15Z" stroke="#8C8C8C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}
export const MyIcon = (props: any) => <Icon component={Svg} {...props} />;