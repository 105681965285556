import React from "react";
import {MyButton, MyImage, MyText} from "../../components";
import {Col, Collapse, Pagination, Row, Tabs, theme as configTheme} from "antd";
import {useNavigate} from "react-router-dom";
import {MyAliasToken} from "../../assets/themes/defaultTheme";
import {MyBreadcrumb} from "../../components/common/MyBreadcrumb/MyBreadcrumb";
import {MyCard} from "../../components/common/MyCard/MyCard";
import {CollapseArrow, NewIcon} from "../../assets";
import NotificationsImage from "../../assets/images/notifications/noitifcations-image.svg";
import {StyledNotificationsContainer} from "./index.styles";
import {MyCollapse} from "../../components/common/MyCollapse/MyCollapse";
import dayjs from "dayjs";

const {useToken} = configTheme
export const NotificationsPage = () => {

  const navigate = useNavigate();

  //@ts-ignore
  const {token}: { token: MyAliasToken } = useToken();

  const handleRenderNewIcon = (isNew: boolean) => {
    return isNew ? <NewIcon style={{marginRight: 12}}/> : null
  }

  const mockCollapse = [
    {
      key: 1,
      label: "Вам доступен результат тестирования, пройдите по ссылке",
      isNew: true,
      children: <p>content</p>,
      header: () => (<div>Hello world!</div>)
    },
    {
      key: 2,
      label: "Плановые технические работы",
      isNew: true,
      children: <p>content</p>,
      header: () => (<div>Hello world!</div>)
    },
    {
      key: 3,
      label: "Сообщение от Службы поддержки пользователей",
      isNew: false,
      children: <div>
        texttexttexttexttexttexttexttexttexttexttexttext
      </div>
    }
  ]

  const mockTabs = [
    {label: "Новые", key: 11, children: <MyCollapse expandIconPosition="end" defaultActiveKey={['2']} expandIcon={(panelProps) => <CollapseArrow {...panelProps} />}
                                                  items={mockCollapse.map((mockItem: any) => ({
                                                    id: mockItem.key,
                                                    label: <Row justify="space-between" align="middle"><div style={{display: "flex"}}>{handleRenderNewIcon(mockItem.isNew)} <MyText size={18} weight={mockItem.isNew ? 700 : 400} lineHeight="24px" color={token.titleText}>{mockItem.label}</MyText></div> <MyText color={token.icon} size={16} lineHeight="24px">{dayjs().format("DD.MM.YYYY, HH:mm")}</MyText></Row>,
                                                    children: mockItem.children
                                                  }))}/>},
    {label: "Архив", key: 22, children: <><MyCollapse expandIconPosition="end" expandIcon={(panelProps) => <CollapseArrow {...panelProps} />} items={new Array(10).fill(null).map((_, i) => {
        const id = String(i + 100);
        return {
          label: <Row justify="space-between"><MyText size={18} lineHeight="24px" color={token.titleText}>{`Старое уведомление ${id}`}</MyText><MyText color={token.icon} size={16} lineHeight="24px">{dayjs().format("DD.MM.YYYY, HH:mm")}</MyText></Row>,
          key: id,
          children: `Content of Pane ${id}`,
        };
      })}/>
      <Row justify="space-between" align="middle" style={{marginTop: 32}}><MyText size={16} lineHeight="24px" color={token.icon}>Показаны записи 1-10 из 100</MyText> <Pagination showSizeChanger={false} total={500}/></Row>
      </>},
  ]

  return(
    <>
      <MyBreadcrumb title="Уведомления"/>

      <StyledNotificationsContainer style={{padding: 16}} gutter={16}>
        <Col span={18}>
          <MyCard>
            {/*@ts-ignore*/}
            <Tabs defaultActiveKey="11" items={mockTabs}/>

          </MyCard>
        </Col>
        <Col span={6}>
          <MyCard>
            <Row style={{flexDirection: 'column'}} align="middle" justify="center">
              <MyText size={24} weight={500} lineHeight={"32px"} style={{marginTop: 24, marginBottom: 30}}>Возникли
                вопросы?</MyText>
              <MyImage src={NotificationsImage} width={268} height={268}/>
              <MyButton style={{marginTop: 30, marginBottom: 40, width: 154}} size="large" ghost type="primary">Написать
                нам</MyButton>
            </Row>
          </MyCard>
        </Col>
      </StyledNotificationsContainer>
    </>
  )
}