export const ROUTE_PATH: any = {
  // common
  ERROR_PAGE: '/404',
  AUTH: '/auth',
  RESTORE: '/restore',
  PROFILE: '/profile',
  MAIN: '/',
  HELP: '/help',
  REPORT: '/report',
  TASKS: '/tasks',
  TASKS_LIST: '/tasks/:taskId',
  CURRENT_TASK: '/tasks/:taskId/:currentTask',
  NOTIFICATIONS: '/notifications',
  SUBSCRIPTION: "/subscription"
}