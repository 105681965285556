import styled from "styled-components";

export const MyBreadcrumbStyles = styled.div`
  background: var(--c-table-header);
  height: 56px;
  //box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
  border-bottom: 1px solid var(--c-line);
  position: relative;
  z-index: 5;
  
  //.edit-lesson{
  //  border-color: #AE7DD1;
  //  color: #AE7DD1;
  //}
`