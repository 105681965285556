import styled from "styled-components";
import {Layout} from "antd";

export const StyledLayout = styled(Layout)`
  &.theme-light {
    .ant-layout-sider {
      background-color: #fff;
      .ant-menu-item{
        .anticon {
          margin-left: 0;
        }
        
        &.ant-menu-item-selected{
          path{
            stroke: #fff;
          }
        }
      }
      .sider-button {
        &:after {
          background: #fff;
        }
      }
    }
  }

  &.theme-dark {
    .ant-layout-sider {
      background-color: #1E1E1E;
      .ant-menu-item{
        .anticon {
          margin-left: 0;
        }
        
        &.ant-menu-item-selected{
          path{
            stroke: #fff;
          }
        }
      }
      .sider-button {
        &:after {
          background: #1E1E1E;
        }
      }
    }
  }
`