import {MyBreadcrumbStyles} from "./MyBreadcrumb.styles";
import {MyBreadcrumbProps} from "./MyBreadcrumb.props";
import React from "react";
import {useNavigate} from "react-router-dom";
import {Col, Row} from "antd";
import {MyButton} from "../MyButton/MyButton";
import {ArrowLeftOutlined} from "@ant-design/icons";

export const MyBreadcrumb = ({title, extra, isBack, link, ...props}: MyBreadcrumbProps): React.ReactElement => {

  const navigate = useNavigate()

  return (
    <MyBreadcrumbStyles>
      <Col style={{margin: "auto"}}>
        <Row justify="space-between" align="middle" style={{height: 54,}}>
          <Col>
            <Row align="middle">
              {isBack ? <MyButton  icon={<ArrowLeftOutlined/>} onClick={() => {
                link ? navigate(link) : navigate(-1)
              }} type='text'/> : null}
              <h1 style={{marginLeft: isBack ? 0 : 16}}>{title}</h1>
            </Row>
          </Col>
          {extra ? extra : null}
        </Row>
      </Col>
    </MyBreadcrumbStyles>
    // <MyBreadcrumbStyles {...props} items={items} itemRender={(route, params, routes, paths) => {
    //   return (
    //     route?.path ? <Link to={route?.path}>{route?.title}</Link> : route?.title
    //   )
    // }}/>
  )
}