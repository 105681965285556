import React, {useState} from 'react';
import {Menu, Button, Col, Row, Image, theme as configTheme} from "antd";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {RightOutlined, LeftOutlined} from "@ant-design/icons";

import {StyledSider, StyledUserInfo} from "./Sider.styles";
import {SiderProps} from "./Sider.props";
import {MyText} from "../../components";

// import LogoIcon from '../../assets/icons/header/logo.svg'
// import LogoText from '../../assets/icons/header/logoText.svg'
import MockImage from '../../assets/images/header/mockImage.png'

import {
  ContactsMenu,
  HelpMenu,
  HomeMenu,
  Logo,
  LogoText,
  MyAccountMenu,
  NotificationMenu,
  SubscriptionMenu, TaskMenu
} from "../../assets";
import {MyAliasToken} from "../../assets/themes/defaultTheme";

const {useToken} = configTheme
export const Sider = (props: SiderProps): React.ReactElement => {
  const navigate = useNavigate();
  const location = useLocation();
  //@ts-ignore
  const {token}:{token: MyAliasToken} = useToken();
  const childrenLocation = `/${location.pathname.split('/')[1]}` || '/'

  const handleMenuItemClick = (path: any) => {
    navigate(path)
  }

  const menu = [
    {
      key: "/",
      icon: <HomeMenu />,
      label: "Главная"
    },
    {
      key: "/tasks",
      icon: <TaskMenu />,
      label: "Задачи"
    },
    {
      key: "/subscription",
      icon: <SubscriptionMenu />,
      label: "Подписка"
    },
    {
      key: "/account",
      icon: <MyAccountMenu />,
      label: "Мой аккаунт"
    },
    {
      key: "/notifications",
      icon: <NotificationMenu />,
      label: "Уведомления"
    },
    {
      key: "/help",
      icon: <HelpMenu />,
      label: "Помощь"
    },
    {
      key: "/contacts",
      icon: <ContactsMenu />,
      label: "Контакты"
    },
  ]

  const [collapsed, setCollapsed] = useState(false);

  return (
    <StyledSider {...props} width={208} trigger={null} collapsible collapsed={collapsed} collapsedWidth={48}>
      <Link to='/' className='sider-logo'>
        <Logo />
        {/*<MyImage src={L} width={48} height={48}/>*/}

        {
          !collapsed && <Col style={{marginLeft: 12}}><LogoText /></Col>
            // <MyImage src={LogoText} width={74} height={32}/>
        }
      </Link>
      <StyledUserInfo align="middle">
        <Image src={MockImage} width={32} height={32} />
        {collapsed ? null : (
          <Row style={{flexDirection: "column", paddingLeft: 12}}>
            <MyText lineHeight="20px" weight={500} color={token.titleText}>Мустафина Ж.С.</MyText>
            <MyText size={12} lineHeight="14px" weight={500} color={token.icon}>Начинающий</MyText>
          </Row>
        )}
      </StyledUserInfo>

      <Menu
        theme='light'
        mode="vertical"
        items={menu}
        onClick={({key}) => handleMenuItemClick(key)}
        selectedKeys={[location.pathname, childrenLocation]}
      />

      <Button type='text' icon={collapsed ? <RightOutlined size={7}/> : <LeftOutlined size={7}/>}
              onClick={() => setCollapsed(!collapsed)} className='sider-button'/>
    </StyledSider>
  );
};

