import Icon from "@ant-design/icons";

const Svg = () => {
  return (
    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="12" y="24" width="8" height="8" rx="4" fill="#F32D2D"/>
      <rect x="24" y="24" width="8" height="8" rx="4" fill="#FAAD14"/>
      <rect x="36" y="24" width="8" height="8" rx="4" fill="#FAAD14"/>
    </svg>
  )
}
export const MyIcon = (props: any) => <Icon component={Svg} {...props} />;