import React, {useCallback} from 'react';
import {createSearchParams, generatePath, useNavigate} from "react-router-dom";

import {Col, Row, theme as configTheme} from "antd";
import {MyBreadcrumb} from "../../components/common/MyBreadcrumb/MyBreadcrumb";
import {MyText} from "../../components";
import {MyAliasToken} from "../../assets/themes/defaultTheme";
import {FifthIcon, FirstIcon, FourthIcon, SecondIcon, SixthIcon, ThirdIcon} from "../../assets";

import {StyledCardContainer, StyledTasksPageContainer} from "./index.styles";
import {ROUTE_PATH} from "../../constants";

const {useToken} = configTheme

export const TasksPage = (): React.ReactElement => {
  const navigate = useNavigate();
  //@ts-ignore
  const {token}:{token: MyAliasToken} = useToken();

  const mockData = [
    {
      id: 1,
      title: "Техники тест дизайна",
      subtitle: "Изучите техникки тест дизайна и попробуйте их на праткике",
      status: "available",
      icon: <FirstIcon />
    },
    {
      id: 1,
      title: "Тестирование АПИ",
      subtitle: "Задачи на тестирование API",
      status: "subscription",
      icon: <SecondIcon />
    },
    {
      id: 1,
      title: "Тестирование БД",
      subtitle: "Задачи на тестирование связанные с базами данных",
      status: "subscription",
      icon: <ThirdIcon />
    },
    {
      id: 1,
      title: "Тестирование на грейд",
      subtitle: "Пройди тестирование и узай свой грейд",
      status: "subscription",
      icon: <FourthIcon />
    },
    {
      id: 1,
      title: "Тестирование веб",
      subtitle: "Тестируем веб приложения",
      status: "subscription",
      icon: <FifthIcon />
    },
    {
      id: 1,
      title: "Испытания",
      subtitle: "Изучите техники тест дизайна и попробуйте их на практике",
      status: "subscription",
      icon: <SixthIcon />
    },
  ]

  const handleBackgroundColor = (status: string) => {
    const obj = {
      "subscription": "rgba(78, 175, 81, 0.15)",
      "available": "rgba(24, 144, 255, 0.15)",
    }

    //@ts-ignore
    return obj[status]
  }

  const handleClickPlan = useCallback((type: string, obj: any) => {
    const path = {
      pathname: generatePath(ROUTE_PATH.TASKS_LIST, {taskId: obj.id}),
    }

    navigate(path);
  }, [mockData])

  return(
    <>
      <MyBreadcrumb title="Практики" />
      <StyledTasksPageContainer>
        <Row gutter={[16, 16]}>
          {mockData.map((item:any, idx: any) => (
            <Col onClick={() => handleClickPlan('', item)} key={"task-card-" + idx} span={12}>
              <StyledCardContainer>
                <Row>
                  <Row justify="center" align="middle" style={{height: 72, width: 72, padding: 8, borderRadius: 100, border: `1px solid ${handleBackgroundColor(item!.status!)}`,   }}>
                    <Row justify="center" align="middle" style={{height: 56, width: 56, borderRadius: 100, background: handleBackgroundColor(item!.status!)}}>
                      {item.icon}
                    </Row>
                  </Row>
                  <Row style={{flexDirection: "column", marginLeft: 24}} justify="center">
                    <MyText className="title" weight={500} size={20} lineHeight="28px" color={token.titleText}>{item.title}</MyText>
                    <MyText size={16} lineHeight="24px" color={token.subtitle}>{item.subtitle}</MyText>
                  </Row>
                </Row>
              </StyledCardContainer>
            </Col>
          ))}
        </Row>
      </StyledTasksPageContainer>
    </>
  )
}