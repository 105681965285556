import styled from "styled-components";
import {Input} from "antd";

export const MyInputStyles = styled(Input)`
  .ant-input{
    background-color: transparent !important;
  }
`
export const MyInputPasswordStyles = styled(Input.Password)`
  .ant-input{
    background-color: transparent !important;
  }
  
  .ant-input-password-icon{
    color: inherit !important;
  }
  
`
export const MyTextareaStyles = styled(Input.TextArea)`
  .ant-input{
    background-color: transparent !important;
  }
`



export const MyInputSearchStyles = styled(Input.Search)`
  .ant-input-wrapper{
    .ant-input-group-addon{
      background-color: ${props => props.theme.buttonBackgroundColor} !important;
      .ant-btn{
        color: ${props => props.theme.iconColor} !important;
        border-top: 1px solid ${props => props.theme.borderColor} !important;
        border-right: 1px solid ${props => props.theme.borderColor} !important;
        border-bottom: 1px solid ${props => props.theme.borderColor} !important;
        border-left: none;
      }
    }
  }
`