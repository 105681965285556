import styled from 'styled-components'

export const StyledSubscriptionContainer = styled.div`
  margin: 16px;
  .ant{
    &-table{
      &-thead{
        td, th {
          background: #fff !important;
          border-bottom: none !important;

          &:before{
            background-color: #fff !important;
          }
        }
      }
      
      &-tbody{
        tr:last-child{
          td{
            border-bottom: none !important;
          }
        }
      }
      
      &-summary{
        td{
          border-bottom: none !important;
        }
      }
    }
    
    &-btn:disabled{
      border-color: var(--c-line);
      
      span {
        font-size: 16px !important;
        color: var(--c-subtitle) !important;
      }
    }
  }
`