import {MyImageProps} from "./MyImage.props";
import {MyImageStyles} from "./MyImage.styles";
import React from "react";

export const MyImage = ({...props}: MyImageProps): React.ReactElement => {
  return (
    <MyImageStyles
      {...props}
      preview={false}
    />
  )
}