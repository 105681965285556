import {createReducer, PayloadAction} from "@reduxjs/toolkit";
import {
  FETCH,
  DONE,
  FAIL,
  FORM,
  GET,
} from "../constants";

import reducerStatuses from "../status";

const initialState = {

};

export default createReducer(initialState, builder =>
  builder

)