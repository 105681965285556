import styled from 'styled-components'
import {Row} from "antd";

export const StyledNotificationsContainer = styled(Row)`
  margin: 0 !important;
  
  .ant{
    &-tabs{
      &-nav{
        background-color: var(--c-table-header);
        padding-left: 24px;

        .ant-tabs-tab{
          color: var(--c-subtitle);
          line-height: 24px;
          font-size: 16px;
          font-weight: 500;
        }

        &:before{
          border-color: var(--c-line);
        }
      }
      
      &-content-holder{
        padding: 0 24px 24px 24px;
      }
    }
    
    &-pagination{
      li{
        a{
          display: flex;
          align-items: center;
          justify-content: center;
          height: 32px;
          width: 32px;
          
          .ant-pagination-item-container{
            position: relative;
            left: -5px;
          }
        }
        
        &:not(.ant-pagination-item-active){
          border: 1px solid var(--c-line);
        }
      }
    }
  }
`