import {DefaultTokenTheme} from "./defaultTheme";
import {ThemeConfig} from "antd/es/config-provider/context";

export const LightTheme: ThemeConfig | undefined = {
  token: {
    ...DefaultTokenTheme,
    colorPrimary: '#4EAF51',
    colorPrimaryBg: "#E1E0FF", //E1E0FF
    colorText: 'rgba(0, 0, 0, 0.85)',
    colorTextSecondary: "rgba(0, 0, 0, 0.45)",
    colorBgContainer: '#fff'
  },
  components: {
    Layout: {
      colorBgHeader: '#fff',
      colorBgBody: 'rgba(240, 242, 245, 1)'
    },
    Input: {
      controlHeight: 40,
      colorBgContainer: '#fff',
      colorText: 'rgba(0, 0, 0, 0.85)',
      colorBorder: '#D9D9D9',
      colorBgElevated: '#fff',
      controlItemBgHover: 'rgba(107, 100, 255, .1)',
      controlItemBgActive: '#A0D4A1',
      colorTextPlaceholder: '#D9D9D9',
      colorTextQuaternary: '#D9D9D9',
      colorTextDisabled: '#D9D9D9',
    },
    Select: {
      controlHeight: 40,
      colorBgContainer: '#fff',
      colorText: 'rgba(0, 0, 0, 0.85)',
      colorBorder: '#D9D9D9',
      colorBgElevated: '#fff',
      controlItemBgHover: '#E6F3E5',
      controlItemBgActive: '#A0D4A1',
      colorTextPlaceholder: '#71BF74',
      colorTextQuaternary: '#838383',
      colorTextDisabled: '#D9D9D9',
    },
    Button: {
      colorBgContainer: "transparent",
      colorBgTextHover: 'rgba(107, 100, 255, .1)',
    },
    Popover: {
      colorBgElevated: '#fff'
    },
    Typography: {
      colorText: "rgba(0, 0, 0)",
      colorTextDescription: 'rgba(0, 0, 0, 0.45)'
    },
    Modal: {
      headerBg: '#4EAF51',
      colorText: "rgba(0, 0, 0)",
      colorTextHeading: '#fff',
      fontSizeHeading5: 24,
      fontWeightStrong: 700,
      colorBgElevated: '#fff',
      colorBgMask: 'rgba(0, 0, 0, 0.60)'
    },
    Collapse: {
      colorBorder: '#D9D9D9'
    },
    Form: {
      marginLG: 12
    },
    Divider: {
      margin: 0,
      marginLG: 0,
      marginXS: 0,
      colorSplit: '#D9D9D9'
    },
    DatePicker: {
      controlHeight: 40,
      colorBgContainer: '#fff',
      colorText: 'rgba(0, 0, 0, 0.85)',
      colorTextDescription: 'rgba(0, 0, 0, 0.85)',
      colorIcon: 'rgba(0, 0, 0, 0.85)',
      colorBorder: '#D9D9D9',
      colorSplit: '#D9D9D9',
      colorBgElevated: '#fff',
      controlItemBgHover: 'rgba(107, 100, 255, .1)',
      controlItemBgActive: '#A0D4A1',
      colorTextPlaceholder: '#838383',
      colorTextQuaternary: '#838383',
      colorTextDisabled: '#D9D9D9',
    },
    Empty: {
      colorText: '#838383',
      colorTextDisabled: '#838383',
    },
    Table: {
      padding: 6,
      paddingContentVerticalLG: 6
    }
  }
}