import Icon from "@ant-design/icons";

const Svg = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M12.0005 12C14.455 12 16.4444 9.98477 16.4444 7.49953C16.4444 5.0143 14.455 3 12.0005 3C9.54593 3 7.55558 5.0143 7.55558 7.49953C7.54729 9.97638 9.52382 11.9916 11.9692 12H12.0005Z" stroke="#8C8C8C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M12.0005 14.7C7.6852 14.7 4 15.3271 4 17.8388C4 20.3504 7.66182 21 12.0005 21C16.3159 21 20 20.3718 20 17.8612C20 15.3506 16.3392 14.7 12.0005 14.7Z" stroke="#8C8C8C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  )
}
export const MyIcon = (props: any) => <Icon component={Svg} {...props} />;