import React from "react";
import {MyBreadcrumb} from "../../components/common/MyBreadcrumb/MyBreadcrumb";
import {StyledHelpContainer} from "./index.styles";
import {MyCard} from "../../components/common/MyCard/MyCard";
import {MyButton, MyImage, MyText} from "../../components";
import {Col, Row} from "antd";
import HelpImage from "../../assets/images/help/help-image.svg";
import {MyCollapse} from "../../components/common/MyCollapse/MyCollapse";
import {CollapseArrow} from "../../assets";

export const HelpPage = () => {

  const mockCollapse = [
    {
      key: 1,
      label: "Вам доступен результат тестирования, пройдите по ссылке",
      isNew: true,
      children: <p>content</p>,
      header: () => (<div>Hello world!</div>)
    },
    {
      key: 2,
      label: "Плановые технические работы",
      isNew: true,
      children: <p>content</p>,
      header: () => (<div>Hello world!</div>)
    },
    {
      key: 3,
      label: "Сообщение от Службы поддержки пользователей",
      isNew: false,
      children: <div>
        texttexttexttexttexttexttexttexttexttexttexttexttexttexttexttexttexttext
      </div>
    }
  ]

  return(
    <>
      <MyBreadcrumb title="Помощь" />

      <StyledHelpContainer style={{padding: 16}} gutter={16}>
        <Col span={18}>
          <MyCard style={{padding: 16}}>
            <MyText>База знаний</MyText>

            <MyCollapse items={mockCollapse} expandIconPosition="end" defaultActiveKey={['2']} expandIcon={(panelProps) => <CollapseArrow {...panelProps} />} />
          </MyCard>
        </Col>
        <Col span={6}>
          <MyCard>
            <Row style={{flexDirection: 'column'}} align="middle" justify="center">
              <MyText size={24} weight={500} lineHeight={"32px"} style={{marginTop: 24, marginBottom: 30}}>Возникли
                вопросы?</MyText>
              <MyImage src={HelpImage} width={268} height={268}/>
              <MyButton style={{marginTop: 30, marginBottom: 40, width: 154}} size="large" ghost type="primary">Написать
                нам</MyButton>
            </Row>
          </MyCard>
        </Col>
      </StyledHelpContainer>
    </>
  )
}