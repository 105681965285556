import React, {useState} from "react";
import {MyBreadcrumb} from "../../../../components/common/MyBreadcrumb/MyBreadcrumb";
import {MyCard} from "../../../../components/common/MyCard/MyCard";
import {MyButton, MyText} from "../../../../components";
import {
  Button,
  Card,
  Checkbox,
  Col,
  Collapse,
  Divider,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  theme as configTheme
} from "antd";
import {MyAliasToken} from "../../../../assets/themes/defaultTheme";
import {CollapseArrow, DifficultEasy} from "../../../../assets";
import {StyledCurrentTaskContainer} from "./index.styles";

const {useToken} = configTheme;
const {Option} = Select;
export const CurrentTask = () => {

  //@ts-ignore
  const {token}: { token: MyAliasToken } = useToken();
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false)

  let resultObj = {insideCell: [], total: ""} as any

  const handleCheckResult = () => {
    setLoading(true)
    setVisible(true)

    setTimeout(() => {
      setLoading(false)
      resultObj = {
        insideCell: ["Эффективность набора тестов: 11%", "Эффективность набора тестов: 25%", "Общий балл: 3%"],
        total: "Ваш результат равен 3% 🙁"
      }
    }, 5000)
  }

  const mock = [
    {
      key: 2,
      label: "Описание задачи",
      difficult: 1,
      children: <div>
        <Row style={{flexDirection: "column", fontSize: 16, lineHeight: "24px", color: token.titleText}}>
            <span> Ваша команда разрабатывает программное обеспечение для контроллера термостата, который имеет переключатель для включения и выключения обогрева. Был реализован следующий алгоритм:</span>
            <span style={{paddingLeft: 8}}> Если выключатель обогрева включен: </span>
            <span
              style={{paddingLeft: 8}}> ○ Если измеренная температура равна или превышает 23°C, обогрев отключается. </span>
            <span style={{paddingLeft: 8}}> ○ Если измеренная температура ниже 23°C, включается обогрев. </span>

            <span style={{paddingLeft: 8}}> Если выключатель обогрева выключен: </span>
            <span style={{paddingLeft: 8}}> ○ Если измеренная температура опускается ниже 5°C, включается обогрев, чтобы предотвратить замерзание воды в системе отопления. </span>
            <span style={{paddingLeft: 8}}> ○ В противном случае нагрев отключается. </span>

            <span> Определите оптимальный (эффективный и результативный) набор граничных тестов для адекватного тестирования контроллера термостата.</span>
            <span>  </span>
        </Row>
      </div>
    }
  ]

  return (
    <>
      <MyBreadcrumb title="Техники тест дизайна" isBack/>

      <StyledCurrentTaskContainer>
        <MyCard style={{padding: 16, margin: 16}}>
          <Row justify="space-between">
            <MyText size={24} lineHeight="32px" weight={500} color={token.titleText}>Термостат</MyText>
            <DifficultEasy/>
          </Row>

          <Collapse expandIconPosition="end" defaultActiveKey={['2']}
                    expandIcon={(panelProps) => <CollapseArrow {...panelProps} />}
                    items={mock}/>

          <MyText size={20} lineHeight="28px" weight={500} color={token.titleText}>Тест кейсы</MyText>

          <Form
            form={form}
            name="dynamic_form_complex"
            autoComplete="off"
            initialValues={{ items: [
                {
                  list: [
                    {first: "", second: "", third: "", fourth: "", fifth: ""},
                  ]
                }
              ] }}
          >
            <Form.List name="items">
              {(fields, { add, remove }) => (
                <div style={{ display: 'flex', rowGap: 16, flexDirection: 'column' }}>
                  {fields.map((field) => (
                    <Card
                      size="small"
                      title={`Тест кейс ${field.name + 1}`}
                      key={field.key}
                    >

                      {/* Nest Form.List */}
                      <Form.Item>
                        <Form.List name={[field.name, 'list']}>
                          {(subFields, subOpt) => (
                            <div style={{display: 'flex', flexDirection: 'column', rowGap: 16}}>
                              {subFields.length > 0 ? (subFields.map((subField) => (
                                <div key={subField.key}>
                                  <Row justify="space-between">
                                    <Col>
                                      <MyText>Депозит</MyText>
                                    </Col>
                                    <Col>
                                      <MyText>Возраст</MyText>
                                    </Col>
                                    <Col>
                                      <MyText>Годовая процентная ставка</MyText>
                                    </Col>
                                    <Col>
                                      <MyText>Условие</MyText>
                                    </Col>
                                    <Col>
                                      <MyText>Действия</MyText>
                                    </Col>
                                  </Row>
                                  <Divider />
                                  <Row justify="space-between">
                                    <Form.Item name={[subField.name, 'first']}>
                                      <InputNumber style={{width: 90}} placeholder="Введите депозит"/>
                                    </Form.Item>
                                    <Form.Item name={[subField.name, 'second']}>
                                      <InputNumber style={{width: 90}} placeholder="Введите возраст"/>
                                    </Form.Item>
                                    <Form.Item name={[subField.name, "third"]}>
                                      <Select style={{width: 296}}>
                                        <Option>1.0%</Option>
                                        <Option>1.3%</Option>
                                        <Option>1.5%</Option>
                                      </Select>
                                    </Form.Item>
                                    <Form.Item name={[subField.name, "fourth"]}>
                                      <Checkbox/>
                                    </Form.Item>
                                    <Form.Item name={[subField.name, "fifth"]}>
                                      <Row>
                                        <Button onClick={() => subOpt.add()}>add</Button>
                                        <Button onClick={() => subOpt.remove(subField.name)}>remove</Button>
                                      </Row>
                                    </Form.Item>
                                  </Row>

                                  {/*<CloseOutlined*/}
                                  {/*  onClick={() => {*/}
                                  {/*    subOpt.remove(subField.name);*/}
                                  {/*  }}*/}
                                  {/*/>*/}
                                </div>
                              ))
                              ) : <Row justify="center"><MyButton type="primary" onClick={() => subOpt.add()}>Создать новый тест-кейс</MyButton></Row>}

                            </div>
                          )}
                        </Form.List>
                      </Form.Item>
                    </Card>
                  ))}

                  <Row justify="start">
                    <MyButton type="primary" onClick={() => add({
                          list: [
                            {first: "", second: "", third: "", fourth: "", fifth: ""},
                          ]
                    })}>Добавить тестовый кейс</MyButton>
                  </Row>
                </div>
              )}
            </Form.List>
          </Form>

          <Row justify="center">
            <MyButton type="primary" onClick={() => handleCheckResult()}>Проверить</MyButton>
          </Row>

          {visible ? (
            <div style={{border: "1px solid red"}}>
              {resultObj && resultObj!.insideCell.length > 0 && !loading ? (
                resultObj!.insideCell.length > 0 && resultObj!.insideCell!.map((item: any) => (
                  <MyText>
                    {item}
                  </MyText>
                ))
              ) : <MyText>Загрузка, подождите...</MyText>}
            </div>
          ) : null}
        </MyCard>
      </StyledCurrentTaskContainer>
    </>
  )
}