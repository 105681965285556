import {DefaultTokenTheme} from "./defaultTheme";
import {ThemeConfig} from "antd/es/config-provider/context";

export const DarkTheme: ThemeConfig | undefined = {
  token: {
    ...DefaultTokenTheme,
    colorPrimaryBg: "#353352",
    colorText: 'rgba(255, 255, 255, 0.85)',
    colorTextSecondary: "#8D8D8D",
    colorBgContainer: '#1E1E1E'
  },
  components: {
    Layout: {
      colorBgHeader: '#1E1E1E',
      colorBgBody: '#141414'
    },
    Input: {
      controlHeight: 40,
      colorBgContainer: '#272727',
      colorText: 'rgba(255, 255, 255, 0.85)',
      colorBorder: '#3D3D3D',
      colorBgElevated: '#272727',
      controlItemBgHover: 'rgba(107, 100, 255, .1)',
      controlItemBgActive: '#968cff',
      colorTextPlaceholder: '#838383',
      colorTextQuaternary: '#838383',
      colorTextDisabled: '#3D3D3D',
    },
    Select: {
      controlHeight: 40,
      colorBgContainer: '#272727',
      colorText: 'rgba(255, 255, 255, 0.85)',
      colorBorder: '#3D3D3D',
      colorBgElevated: '#272727',
      controlItemBgHover: 'rgba(107, 100, 255, .1)',
      controlItemBgActive: '#968cff',
      colorTextPlaceholder: '#838383',
      colorTextQuaternary: '#3D3D3D',
      colorTextDisabled: '#3D3D3D',
    },
    Button: {
      colorBgContainer: "transparent",
      colorBgTextHover: 'rgba(107, 100, 255, .1)'
    },
    Popover: {
      colorBgElevated: '#272727'
    },
    Typography: {
      colorText: "rgb(255,255,255)",
      colorTextDescription: '#8D8D8D'
    },
    Modal: {
      colorText: "rgb(255,255,255)",
      colorTextHeading: 'rgb(255,255,255)',
      fontSizeHeading5: 24,
      fontWeightStrong: 700,
      colorBgElevated: '#1E1E1E',
      colorBgMask: 'rgba(0, 0, 0, 0.60)'
    },
    Collapse: {
      colorBorder: '#3D3D3D'
    },
    Form: {
      marginLG: 12
    },
    Divider: {
      margin: 0,
      marginLG: 0,
      marginXS: 0,
      colorSplit: '#3D3D3D'
    },
    DatePicker: {
      controlHeight: 40,
      colorBgContainer: '#272727',
      colorText: 'rgba(255, 255, 255, 0.85)',
      colorTextDescription: 'rgba(255, 255, 255, 0.85)',
      colorIcon: 'rgba(255, 255, 255, 0.85)',
      colorBorder: '#3D3D3D',
      colorSplit: '#3D3D3D',
      colorBgElevated: '#272727',
      controlItemBgHover: 'rgba(107, 100, 255, .1)',
      controlItemBgActive: '#968cff',
      colorTextPlaceholder: '#838383',
      colorTextQuaternary: '#838383',
      colorTextDisabled: '#3D3D3D',
    },
    Empty: {
      colorText: '#838383',
      colorTextDisabled: '#838383',
    },
    Table: {
      padding: 6,
      paddingContentVerticalLG: 6
    }
  }
}