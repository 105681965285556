import Icon from "@ant-design/icons";

const Svg = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4 7.78571L11.0133 12.67C11.3055 12.8736 11.6488 12.9823 12 12.9823C12.3512 12.9823 12.6945 12.8736 12.9867 12.67L20 7.78571M5.77778 18H18.2222C18.6937 18 19.1459 17.8043 19.4793 17.4561C19.8127 17.1078 20 16.6354 20 16.1429V6.85714C20 6.3646 19.8127 5.89223 19.4793 5.54394C19.1459 5.19566 18.6937 5 18.2222 5H5.77778C5.30628 5 4.8541 5.19566 4.5207 5.54394C4.1873 5.89223 4 6.3646 4 6.85714V16.1429C4 16.6354 4.1873 17.1078 4.5207 17.4561C4.8541 17.8043 5.30628 18 5.77778 18Z" stroke="#8C8C8C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>

  )
}
export const MyIcon = (props: any) => <Icon component={Svg} {...props} />;