import styled from "styled-components";

export const LanguageDefaultSwitchStyles = styled.div`
  display: inline-block;
  margin: 0 5px;
  width: 92px;
  height: 40px;

  label > span {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
    font-size: var(--fs-body-2);
    text-transform: uppercase;
    width: 50%;
    height: 100%;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid transparent;
    border-radius: 8px;
  }

  label > span.on {
    left: 0;
    color: var(--c-subtitle);
    border-color: transparent;
  }

  label > span.off {
    right: 0;
    color: var(--c-primary);
    border-color: var(--c-primary);
  }

  .checkToggle {
    position: absolute;
    display: none;
    visibility: hidden;
  }

  .checkToggle + label {
    display: block;
    position: relative;
    cursor: pointer;
    outline: none;
    user-select: none;
  }

  input.checkToggleRoundFlat + label {
    width: 98px;
    height: 40px;
  }

  input.checkToggleRoundFlat:checked + label span.on {
    color: var(--c-primary);
    border-color: var(--c-primary);
  }

  input.checkToggleRoundFlat:checked + label span.off {
    color: var(--c-subtitle);
    border-color: transparent;
  }
`