import React from "react";

import {Row, Table} from "antd";

import {MyBreadcrumb, MyButton, MyCard, MyImage, MyText} from "../../components";
import {StyledSubscriptionContainer} from "./index.styles";

import FreeImg from '../../assets/images/subscription/free-img.svg';
import ProImg from '../../assets/images/subscription/pro-img.svg';
import PremiumImg from '../../assets/images/subscription/premium-img.svg';
import {GrayCheck, GrayCross, GreenCheck, RedCross} from "../../assets";


export const SubscriptionPage = () => {

  const currentPlan = 2

  const handleSetImage = (attribute: boolean, plan: number) => {

    const availableIcons = {
      false: <RedCross />,
      true: <GreenCheck />
    }

    const unAvailableIcons = {
      false: <GrayCross />,
      true: <GrayCheck />
    }

    if(plan <= currentPlan){
      //@ts-ignore
      return availableIcons[attribute]
    } else {
      //@ts-ignore
      return unAvailableIcons[attribute]
    }
  }

  const mockData = [
    {
      title: "Техники тест дизайна",
      free: true,
      pro: true,
      premium: true,
    },
    {
      title: "Тестирование на грейд",
      free: false,
      pro: true,
      premium: true,
    },
    {
      title: "Тестирование API",
      free: false,
      pro: true,
      premium: true,
    },
    {
      title: "Тестирование БД",
      free: false,
      pro: true,
      premium: true,
    },
    {
      title: "Тестирование веб",
      free: false,
      pro: false,
      premium: true,
    },
    {
      title: "Испытания",
      free: false,
      pro: false,
      premium: true,
    },
  ]

  const handleSummaryTableContent = (pageData:any) => {
    return(
      <>
        <Table.Summary.Row style={{paddingTop: 8}}>
          <Table.Summary.Cell index={0}></Table.Summary.Cell>
          <Table.Summary.Cell index={1}><Row justify="center"><MyButton disabled>Входит в текущую подписку</MyButton></Row></Table.Summary.Cell>
          <Table.Summary.Cell index={2}><Row justify="center"><MyButton disabled>Текущая подписка</MyButton></Row></Table.Summary.Cell>
          <Table.Summary.Cell index={3}><Row justify="center"><MyButton ghost type="primary">Приобрести</MyButton></Row></Table.Summary.Cell>
        </Table.Summary.Row>
      </>
    )
  }

  const columns = [
    {
      title: "",
      dataIndex: "title",
      key: "title",
      width: 286,
      render: (text:any) => {
        return <Row style={{padding: "16px 24px"}}><MyText lineHeight="24px" size={18}>{text}</MyText></Row>
      }
    },
    {
      title: <Row justify="center"><MyImage src={FreeImg} /></Row>,
      dataIndex: "free",
      key: "free",
      render: (text:any, record:any, icon:any) => {
        return <Row justify="center">{handleSetImage(text, 1)}</Row>
      }
    },
    {
      title: <Row justify="center"><MyImage src={ProImg} /></Row>,
      dataIndex: "pro",
      key: "pro",
      render: (text:any, record:any, icon:any) => {
        return <Row justify="center">{handleSetImage(text, 2)}</Row>
      }
    },
    {
      title: <Row justify="center"><MyImage src={PremiumImg} /></Row>,
      dataIndex: "premium",
      key: "premium",
      render: (text:any, record:any, icon:any) => {
        return <Row justify="center">{handleSetImage(text, 3)}</Row>
      }
    },
  ]

  return(
    <>
      <MyBreadcrumb title="Подписка" />

      <StyledSubscriptionContainer>
        <MyCard style={{padding: 40}}>
          <Table bordered={false} columns={columns} pagination={false} dataSource={mockData} summary={handleSummaryTableContent}/>
        </MyCard>
      </StyledSubscriptionContainer>
    </>
  )
}