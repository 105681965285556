import styled from "styled-components";
import {Row} from "antd";
import {Link} from "react-router-dom";

export const MainPage = styled.div`
  margin: 16px;
  //border-radius: 4px;


  //.ant-image{
  //  width: 95%;
  //}
`

export const MenuItem = styled(Link)`
  padding: 24px;
  border-radius: 4px;
  transition: all 0.2s;
  background-color: #fff;
  
  &:hover {
    background-color: var(--c-light-hover);
    .img{
      background-color: var(--c-dark-press) !important;
      transition: all 0.5s;
    }
    .text{
      color: var(--c-dark-press) !important;
    }
  }

  .text{
    transition: all 0.2s;
  }
  
  .img {
    transition: all 0.5s;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background-color: var(--c-circle-menu);
    padding: 8px
  }
`