import styled from "styled-components";
import {Layout, Row} from "antd";
import {SiderProps} from "./Sider.props";

export const StyledSider = styled(Layout.Sider)<SiderProps>`
  box-shadow: 0px 0px 16px 0px rgba(34, 60, 80, 0.2);

  .sider-button{
    .ant-btn-icon{
      font-size: 10px !important;
    }
  }
  
  &.ant-layout-sider-collapsed{
    .ant-menu-title-content{
      display: none !important;
    }
  }
  
  .ant-menu {
    border: 0 !important;
    background: transparent !important;
    
    &-title-content{
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
    }

    &-item {
      display: flex;
      align-items: center;
      justify-content: start;
      padding-inline: 4px;
      
      
      &-selected{
        background-color: var(--c-primary);
        span{
          color: #fff;
        }
      }

      .ant-image {
        display: flex;
        &-image {
          display: block;
        }
      }

      & > span {
        line-height: 100%;
      }
    }
  }
  
  .sider-logo{
    height: 56px;
    width: 100%;
    display: flex;
    align-items: center;
    background-color: #fff;
    border-bottom: 1px solid var(--c-line);
    padding-left: 12px;
    color: #fff;
  }
  
  .ant-btn{
    position: absolute;
    top: 50%;
    right: -12px;
    transform: translateY(-50%);
    padding: 0;
    border: none;
    width: 14px;
    height: 66px;
    filter: drop-shadow(4px 0px 3px rgba(34, 60, 80, 0.2));
    background: transparent;
    
    &:hover{
      background-color: transparent;
    }
    
    &:after{
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      clipPath: polygon(0 0, 100% 20%, 100% 80%, 0% 100%);
      left: 0;
      top: 0;
      z-index: -1;
    }
  }
`

export const StyledUserInfo = styled(Row)`
  height: 56px;
  padding: 10px 12px;
  background-color: var(--c-table-header);
  border-bottom: 1px solid var(--c-line);
`