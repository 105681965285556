import * as React from "react";
import cl from "classnames";
import {useCallback, useState} from "react";
import {CloseOutlined, UserOutlined} from "@ant-design/icons";
import {Link} from "react-router-dom";
import {Col, Row, Typography, Form, Modal, Checkbox, theme as configTheme} from "antd";

import {LanguageSwitch, MyButton, MyImage, MyInput, MyText} from "../../components";
// import RestoreImage from '../../assets/images/restore-image.svg'
// import RestoreMaskImage from '../../assets/images/restore-mask-image.png'
import {LeftCol, RestorePage, RightCol} from "./index.styles";
import {GlobalToken} from "antd/es/theme/interface";
import {MyAliasToken} from "../../assets/themes/defaultTheme";
import RestoreImage from "../../assets/images/restore/restore-image.svg";
import {RestoreInputPrefix} from "../../assets";

const {useToken} = configTheme
export const Restore = (): React.ReactElement => {
  // @ts-ignore
  const {token}:{token: MyAliasToken} = useToken();
  const [isImageLoad, setIsImageLoad] = useState(false)

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = useCallback(() => {
    setIsModalOpen(true);
  }, [])
  const handleOk = useCallback(() => {
    setIsModalOpen(false);
  }, [])
  const handleCancel = useCallback(() => {
    setIsModalOpen(false);
  }, [])

  const onFinish = useCallback((values: any) => {
    console.log('Success:', values);

    showModal()
  }, [])

  return (
    <>
      <RestorePage>
        <LeftCol span={12}>
          <MyImage
            className={cl(isImageLoad ? 'imageVisible' : 'imageHidden')}
            src={RestoreImage}
            width='70%' height='100%' alt='image'
            onLoad={() => setIsImageLoad(true)}/>
        </LeftCol>
        <RightCol span={12} >
          <Row style={{height: "100%"}} justify='center' align="middle">
            <Col>
              <Row justify='center'>
                <Col span={18} style={{marginBottom: 16}}>
                  <Row justify="center">
                    <Col span={24} style={{marginBottom: 16, textAlign: "center"}}>
                      <MyText align='center' size={40} lineHeight={"48px"} color={token.titleText} weight={600}>
                        Восстановление пароля
                      </MyText>
                    </Col>
                    <Col span={17} style={{textAlign: "center"}}>
                      <MyText align='center' size={18} lineHeight={"24px"} color={token.subtitle} weight={400}>
                        На указанный Вами e-mail будет отправлено письмо, с новым сгенерированным паролем
                      </MyText>
                    </Col>
                  </Row>
                </Col>
                <Col span={18}>
                  <Row align='middle' justify='center'>
                    <Col span={16}>
                      <Form
                        onFinish={onFinish}
                      >
                        <Form.Item
                          label="E-mail"
                          name="E-mail"
                          labelCol={{
                            span: 24,
                          }}
                          wrapperCol={{
                            span: 24,
                          }}
                        >
                          <MyInput prefix={<RestoreInputPrefix/>} placeholder='Введите email'/>
                        </Form.Item>

                        <Form.Item>
                          <Col span={24}>
                            <MyButton width="100%" height={40} type="primary" htmlType="submit" title='Отправить код подтверждения'/>
                          </Col>
                        </Form.Item>
                      </Form>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>

          </Row>
        </RightCol>
      </RestorePage>

      <Modal
        title="Вход в систему «Аналитика»"
        width={708}
        centered
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        okText='Подтвердить'
        cancelText='Отмена'
        className='modal-restore'
        closeIcon={<CloseOutlined style={{color: '#fff'}}/>}
      >
        <Row>
          <Col span={24}>
            <Typography.Title level={5} style={{textAlign: 'center'}}>
              <strong>ТРЕБУЕТСЯ ВАШЕ СОГЛАСИЕ ПО СЛЕДУЮЩИМ ПУНКТАМ:</strong>
            </Typography.Title>
          </Col>

          <Col span={24}>
            <ul>
              <li>
                <strong>1.</strong> Я подтверждаю, что вся представленная информация является достоверной и точной;
              </li>
              <li>
                <strong>2.</strong> Я несу ответственность в соответствии с действующим законодательством РК за
                предоставление заведомо
                ложных или неполных сведений;
              </li>
              <li>
                <strong>3.</strong> Я выражаю свое согласие на необходимое использование и обработку своих персональных
                данных, в том
                числе в информационных системах;
              </li>
              <li>
                <strong>4.</strong> В случае обнаружения представленной пользователями неполной и/или недостоверной
                информации,
                услугодатель ответственности не несет.
              </li>
            </ul>
          </Col>
          <Checkbox.Group>
            <Checkbox value={1}>
              Я подтверждаю свое согласие со всеми вышеперечисленными пунктами
            </Checkbox>
            <Checkbox value={2}>
              <Link to='/'>С пользовательским соглашением</Link> ознакомлен(-а) и согласен(-на)
            </Checkbox>
            <Checkbox value={3}>
              <Link to='/'>С политикой конфиденциальности</Link> ознакомлен(-а) и согласен(-на)
            </Checkbox>
          </Checkbox.Group>
        </Row>
      </Modal>
    </>
  )
}