import React, {useEffect, useMemo, useState} from 'react';

import {useAuth, useLang, useTheme} from "../../hooks";
import {MyButton, MyImage, MySelect, MyText} from "../../components";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {ROUTE_PATH} from "../../constants";
import {THEME as THEMES_CONST, LANG} from '../../constants'
import {useSelector} from "react-redux";

import {CloseOutlined, UserOutlined} from "@ant-design/icons";
import {MenuItem, StyledHeader} from "./Header.styles";
import {HeaderProps} from "./Header.props";
// import {MenuIcon, NotificationIcon, QuitIcon} from "../../assets";
import {Avatar, Badge, Button, Col, Divider, Modal, Popover, Row, Spin, theme as configTheme, Typography} from "antd";
// import {LogoIcon} from "../../assets/icons/header/LogoIcon";
// import {ProfileIcon} from "../../assets/icons/header/ProfileIcon";
import {selectStore} from "../../store/selector";
import {RootState} from "../../store";
import {DayIcon, NotificationIcon, SearchIcon, UserIcon} from "../../assets";
import {MyAliasToken} from "../../assets/themes/defaultTheme";

const {useToken} = configTheme
export const Header = (props: HeaderProps): React.ReactElement => {
  const navigate = useNavigate();
  const {themeConfig} = useTheme()
  //@ts-ignore
  const {token}: { token: MyAliasToken } = useToken();

  const {handleLogout} = useAuth()
  const {handleToggleTheme} = useTheme()
  const {handleChangeLang, lang} = useLang()

  const initialPopoverShowState = {
    menu: false,
    profile: false,
    notification: false
  }

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [popoverShow, setPopoverShow] = useState(initialPopoverShowState)

  // const {profile: {userProfile: profileDataState}}: RootState = useSelector(selectStore)

  const location = useLocation()
  const childrenLocation = `/${location.pathname.split('/')[1]}` || '/'

  useEffect(() => {
    setPopoverShow(initialPopoverShowState)
  }, [location])

  const handleLogoutButton = () => {
    setIsModalOpen(true)
  }

  const handleModalOkButton = () => {
    handleLogout()
  }

  const handleModalCancelButton = () => {
    setIsModalOpen(false)
  }

  const handleToggleThemeButton = () => {
    handleToggleTheme()
  }

  const handleProfile = () => {
    navigate(ROUTE_PATH.PROFILE)
  }

  const handleCurating = () => {
    navigate(ROUTE_PATH.CURATING)
  }

  const handleChangeLangSelect = (value: any) => {
    handleChangeLang(value)
  }

  const profileRender = useMemo(() => {
    return (
      <Row style={{width: 160}}>
        {/*<Col span={24}>*/}
        {/*<Typography.Text strong style={{fontSize: 14}}>*/}
        {/*{`${profileDataState.data?.lastname} ${profileDataState.data?.firstname} ${profileDataState.data?.middlename}`}*/}
        {/*</Typography.Text>*/}
        {/*</Col>*/}

        {/*<Col span={24}>*/}
        {/*  <Typography.Text type='secondary' style={{fontSize: 12}}>*/}
        {/*    Админ*/}
        {/*  </Typography.Text>*/}
        {/*</Col>*/}

        <Col span={24}>
          <Divider style={{margin: '12px 0'}}/>
        </Col>

        <Col span={24}>
          <MyButton type='text' title='Профиль' onClick={handleProfile}
                    style={{width: '100%', display: 'flex', alignItems: 'center', gap: 6, padding: 0}}
                    icon={<UserOutlined style={{fontSize: 20}}/>}/>

        </Col>

        {/*<Col span={24}>*/}
        {/*  <MyButton type='text' title='Кураторство' onClick={handleCurating}*/}
        {/*            style={{width: '100%', display: 'flex', alignItems: 'center', gap: 6, padding: 0}}*/}
        {/*            icon={<UserOutlined style={{fontSize: 20}}/>}/>*/}

        {/*</Col>*/}

        <Col span={24}>
          <MyButton type='text' title='Выйти' onClick={handleLogoutButton}
                    style={{width: '100%', display: 'flex', alignItems: 'center', gap: 6, padding: 0}}
          />
        </Col>
      </Row>
    )

  }, []) //profileDataState.data

  const notificationRender = useMemo(() => {
    return (
      <Row style={{maxWidth: 300}} gutter={[16, 8]}>
        <Col span={24}>
          <Row>
            <Col span={24}>
              <Row>
                <Col span={24}>
                  <MyText color='var(--c-icon)' size={12}>
                    17.01.2023, 08:48 ‎
                  </MyText>
                </Col>
                <Col span={24}>
                  <MyText size={12}>
                    Статус заявки №905 изменен на «Отказано».
                  </MyText>
                </Col>
              </Row>
            </Col>

            <Col span={24}>
              <Row>
                <Col span={24}>
                  <MyText color='var(--c-icon)' size={12}>
                    17.01.2023, 08:48 ‎
                  </MyText>
                </Col>
                <Col span={24}>
                  <MyText size={12}>
                    Статус заявки №905 изменен на «Отказано».
                  </MyText>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>

        <Col span={24}>
          <hr style={{height: 1, backgroundColor: 'var(--c-line)'}}/>
        </Col>

        <Col span={24}>
          <Link to={ROUTE_PATH.NOTIFICATION} style={{width: '100%'}}>
            <MyText align='center' size={16} color='var(--c-link)' style={{display: 'block'}}>
              Все уведомления
            </MyText>
          </Link>
        </Col>
      </Row>
    )
  }, [])

  return (
    <>
      {/*<MyBackdrop isShow={popoverShow.menu || popoverShow.profile || popoverShow.notification}*/}
      {/*            setIsShow={() => setPopoverShow(initialPopoverShowState)}/>*/}
      <StyledHeader {...props}>

        <Row justify='end' align='middle' style={{height: 56}}>
          <Col style={{display: 'flex', alignItems: 'center'}}>
            {/*<Popover placement="bottom" trigger="click" content={menuRender} overlayStyle={{*/}
            {/*  width: '84%',*/}
            {/*  left: '8%'*/}
            {/*}} onOpenChange={(open) => setPopoverShow(prevState => ({*/}
            {/*  ...prevState,*/}
            {/*  menu: open*/}
            {/*}))} open={popoverShow.menu}>*/}
            {/*  <Button type='text' size='large' className='profile'>*/}
            {/*    <MenuIcon size={24} icon={<ProfileIcon/>}/>*/}
            {/*    /!*<span>{menu.find(((item:any) => item.path === childrenLocation) ? menu.find((item:any) => item.path === childrenLocation)?.label : 'Главное меню'}</span>*!/*/}
            {/*  </Button>*/}
            {/*</Popover>*/}
            <MyButton ghost>
              <SearchIcon/>
            </MyButton>
          </Col>
          <Col style={{display: 'flex', alignItems: 'center'}}>
            <Popover placement="bottomLeft" trigger="click" content={notificationRender}
                     onOpenChange={(open) => setPopoverShow(prevState => ({
                       ...prevState,
                       notification: open
                     }))} open={popoverShow.notification}>
              {/*<Badge count={5} offset={[0, 10]} color='var(--c-notification)'>*/}
              <MyButton ghost style={{height: 'auto'}}><NotificationIcon/></MyButton>
              {/*</Badge>*/}
            </Popover>
          </Col>
          {/*profileDataState.loading*/}
          <Spin spinning={false}>
            <Col>
              <Popover placement="bottomLeft" trigger="click" content={profileRender}
                       onOpenChange={(open) => setPopoverShow(prevState => ({
                         ...prevState,
                         profile: open
                       }))} open={popoverShow.profile}>
                <Button type='text' size='large' className='profile'>
                  <UserIcon/>
                  {/*<Avatar size={24} />*/}
                  {/*<span> {`${profileDataState.data?.lastname} ${profileDataState.data?.firstname} ${profileDataState.data?.middlename}`}</span>*/}
                  <MyText size={18} lineHeight="24px" color={token.titleText}>Мустафина Ж.С.</MyText>
                </Button>
              </Popover>
            </Col>
          </Spin>

          <Col>
            <MySelect
              defaultValue="RU"
              style={{
                width: 60,
                color: "red"
              }}
              bordered={false}
              onChange={(value) => handleChangeLangSelect(value)}
              value={lang}
              options={[
                {
                  value: LANG.RU,
                  label: 'RU',
                },
                {
                  value: LANG.KZ,
                  label: 'KZ',
                },
              ]}
            />
          </Col>
          <Col style={{display: 'flex', alignItems: 'center'}}>
            <MyButton ghost><DayIcon/></MyButton>
          </Col>
        </Row>

      </StyledHeader>

      <Modal
        title="Выход из личного кабинета"
        width={708}
        centered
        open={isModalOpen}
        onOk={handleModalOkButton}
        onCancel={handleModalCancelButton}
        okText='Выйти'
        cancelText='Отмена'
        className='modal-restore'
        closeIcon={<CloseOutlined style={{color: '#fff'}}/>}
        okButtonProps={{
          danger: true,
          ghost: true
        }}
      >
        <Row>
          <Col span={24}>
            <MyText
              color={themeConfig?.token?.colorText}
              align='center'
              size={16}
            >
              Вы уверены, что хотите выйти из личного кабинета?
            </MyText>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

