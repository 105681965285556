import Icon from "@ant-design/icons";

const Svg = () => {
  return (
    <svg width="33" height="38" viewBox="0 0 33 38" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_946_6445)">
        <path fillRule="evenodd" clipRule="evenodd" d="M17.0921 0V4.47017L4.36069 11.7363V26.2649L0.5 28.4988V9.5L17.0921 0Z" fill="#A0D4A1"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M17.0921 0V4.47017L29.6393 11.7357V26.2643L33.5 28.4982V9.49941L17.0921 0Z" fill="#4EAF51"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M29.1028 31.0446L17.0921 38L0.5 28.4988L4.36069 26.2649L17.0921 33.5275L25.2422 28.8083L29.1028 31.0446Z" fill="#008939"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M17.09 18.9985L8.53592 14V23.9992L17.09 29V25.7271L11.3362 22.3616V18.8271L17.09 22.1902V18.9985Z" fill="#4EAF51"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M17.0938 18.9985L25.644 14V17.1726L22.843 18.8016V25.5731L19.82 27.3333V20.5595L17.09 22.1902L17.0938 18.9985Z" fill="#008939"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M25.6441 14.0032L17.0912 19L8.53592 14.0032L11.2656 12.4078L17.0912 15.8116L18.7272 14.853L14.6109 12.4495L17.0912 11L21.2075 13.4058L22.9144 12.4078L25.6441 14.0032Z" fill="#A0D4A1"/>
      </g>
      <defs>
        <clipPath id="clip0_946_6445">
          <rect width="33" height="38" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  )
}
export const MyIcon = (props: any) => <Icon component={Svg} {...props} />;