import styled from 'styled-components'
import {Collapse} from "antd";

export const StyledCollapse = styled(Collapse)`

  .ant-collapse-header {
    background: var(--c-table-header);
    align-items: center !important;

    &-text{
      font-size: 18px;
      line-height: 24px;
      color: var(--c-title-text);
    }

    .ant-collapse-arrow {
      svg {
        transform: rotate(180deg) !important;
      }
    }
  }

  .ant-collapse-item-active {
    .ant-collapse-header {


      .ant-collapse-arrow {
        svg {
          transform: rotate(360deg) !important;
        }
      }
    }
  }
`