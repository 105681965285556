import React, {useCallback, useState} from "react";
import {Row, Col, Form, theme as configTheme} from "antd";
import cl from 'classnames'
import {LockOutlined, UserOutlined} from "@ant-design/icons";
import {LanguageDefaultSwitch, MyButton, MyImage, MyInput, MyPassword, MyText} from "../../components";
import AuthImage from '../../assets/images/auth/auth-image.svg'
import {useAuth} from "../../hooks";
import {AuthPage, LeftCol, RightCol} from "./index.styles";
import {useSelector} from "react-redux";
import {MyAliasToken} from "../../assets/themes/defaultTheme";
import {RootState} from "../../store";
import {selectStore} from "../../store/selector";
import {useTranslation} from "react-i18next";
import {AuthInputPrefix, PasswordEyeInputPostfix, PasswordInputPrefix} from "../../assets";
import {ROUTE_PATH} from "../../constants";
import {Link} from "react-router-dom";

const {useToken} = configTheme

export const Auth = (): React.ReactElement => {
  const {t} = useTranslation("common")

  const [isImageLoad, setIsImageLoad] = useState(false)

  const {handleLogin} = useAuth()

  // @ts-ignore
  const {token}:{token: MyAliasToken} = useToken();

  // const {profile: {userProfile: profileDataState}}: RootState = useSelector(selectStore)

  const onFinish = useCallback((values: any) => {
    handleLogin(values)
  }, [])

  const initialState= {
    username: '',
    password: '',
  }

  return (
    <AuthPage>
      <LeftCol span={12}>
        <MyImage
          className={cl(isImageLoad ? 'imageVisible' : 'imageHidden')}
          src={AuthImage}
          width='70%' height='100%' alt='image'
          onLoad={() => setIsImageLoad(true)}/>
      </LeftCol>
      <RightCol span={12}>
        <Row style={{height: '100%'}}>
          {/*<Col span={24}>*/}
          {/*  <Row justify='end'>*/}
          {/*    <Col>*/}
          {/*      <LanguageDefaultSwitch/>*/}
          {/*    </Col>*/}
          {/*  </Row>*/}
          {/*</Col>*/}

          <Row justify="center" align="middle">
            <Col span={12}>
              <Row>
                <Col span={24} style={{marginBottom: 48}}>
                  <Row justify='center' align='middle'>
                    <Col span={24} style={{marginBottom: 24}}>
                      <MyText align='center' size={40} lineHeight={"48px"} color={token.titleText} style={{display: 'block'}}>
                        {/*{t('welcome')}*/}
                        Добро пожаловать!
                      </MyText>
                    </Col>
                    <Col span={24}>
                      <MyText align='center' size={18} lineHeight={"24px"} color={token.subtitle} style={{display: 'block'}}>
                        {/*{t('loginAndPasswordEnter')}*/}
                        Введите логин и пароль
                      </MyText>
                    </Col>
                  </Row>
                </Col>
                <Col span={24}>
                  <Row align='middle' justify='center'>
                    <Col span={24}>
                      <Form onFinish={onFinish}
                            initialValues={process.env.REACT_APP_ENV === 'local' ? initialState : {}}>
                        <Form.Item
                          // label={t('login')}
                          label="Логин"
                          name="username"
                          labelCol={{
                            span: 24,
                          }}
                          wrapperCol={{
                            span: 24,
                          }}
                        >
                          {/*placeholder={t('enterLogin')}*/}
                          <MyInput prefix={<AuthInputPrefix />} placeholder={"Введите логин"}/>
                        </Form.Item>

                        <Form.Item
                          // label={t('password')}
                          label={"Пароль"}
                          name="password"
                          labelCol={{
                            span: 24,
                          }}
                          wrapperCol={{
                            span: 24,
                          }}
                          style={{marginBottom: 24}}
                        >
                          {/*t('enterPassword')*/}
                          <MyPassword prefix={<PasswordInputPrefix/>} suffix={<PasswordEyeInputPostfix />} placeholder="Введите пароль"/>
                        </Form.Item>

                        <Form.Item>
                          <Row justify='end'>
                            <Col style={{marginBottom: 32}}>
                              <Link to={ROUTE_PATH.RESTORE}>
                                <MyText align='center' size={16} lineHeight={"24px"} color={token.link} weight={400} style={{display: 'block'}}>
                                  Забыли пароль?
                                </MyText>
                              </Link>
                            </Col>
                          </Row>
                        </Form.Item>

                        {/*<Form.Item style={{marginBottom: 32}}>*/}
                          {/*<MyButton width='100%' height={40} type="primary" htmlType="submit" title={t('signIn')} loading={profileDataState.loading}/>*/}
                        {/*</Form.Item>*/}

                        <Form.Item>
                          {/*t('connectNewOrganization')*/}
                          <MyButton htmlType="submit" width='100%' height={40} type="primary" title="Войти в систему"/>
                        </Form.Item>
                        <Form.Item>
                          {/*t('connectNewOrganization')*/}
                          <MyButton width='100%' height={40} title="Зарегистрироваться"/>
                        </Form.Item>
                      </Form>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Row>
      </RightCol>
    </AuthPage>
  )
}