import {RouteObject} from "react-router";
import {ROUTE_PATH} from "../constants";
import {
  Auth, Main,
//   Help,
//   Main,
//   Notification,
//   Profile,
//   Report,
  SubscriptionPage,
  TasksPage,
  TaskIdPage,
  CurrentTask,
  NotificationsPage,
  // NotFoundPage,
  HelpPage,
  Restore,
} from "../pages";
import {ProtectedLayout, PublicLayout, RootLayout} from "../layout";


const protectedRoutesArr: RouteObject[] = [
  {
    element: <ProtectedLayout/>,
    children: [
      {
        path: ROUTE_PATH.MAIN,
        element: <Main/>,
      },
      {
        path: ROUTE_PATH.TASKS,
        children: [
          {
            element: <TasksPage />,
            index: true,
          },
          {
            path: ROUTE_PATH.TASKS_LIST,
            children: [
              {
                element: <TaskIdPage />,
                index: true
              },
              {
                path: ROUTE_PATH.CURRENT_TASK,
                element: <CurrentTask />
              }
            ]
          }
        ]
      },
      {
        path: ROUTE_PATH.SUBSCRIPTION,
        element: <SubscriptionPage />
      },
      {
        path: ROUTE_PATH.NOTIFICATIONS,
        element: <NotificationsPage/>,
      },
      {
        path: ROUTE_PATH.HELP,
        element: <HelpPage/>,
      },
      // {
      //   path: ROUTE_PATH.NOTIFICATION,
      //   element: <Notification/>,
      // },
      // {
      //   path: ROUTE_PATH.HELP,
      //   element: <Help/>,
      // },
      // {
      //   path: ROUTE_PATH.REPORT,
      //   element: <Report/>,
      // },
      // {
      //   path: ROUTE_PATH.PROFILE,
      //   element: <Profile/>,
      // },
    ]
  },
]

const publicRoutesArr: RouteObject[] = [
  {
    element: <PublicLayout/>,
    children: [
      {
        path: ROUTE_PATH.AUTH,
        element: <Auth/>,
      },
      {
        path: ROUTE_PATH.RESTORE,
        element: <Restore/>,
      }
    ],
  },
]
export const ROUTES_CONFIG: RouteObject[] = [
  {
    element: <RootLayout/>,
    loader: async ({params}) => {
      // console.log('loader', {params: params})
      return null
    },
    action: async ({params, request}) => {
      // console.log('action', {params, request})
      return null
    },
    children: [
      ...publicRoutesArr,
      ...protectedRoutesArr
    ]
  }
]