import React, {useCallback} from "react";
import {MyBreadcrumb} from "../../../components/common/MyBreadcrumb/MyBreadcrumb";
import {MyCard} from "../../../components/common/MyCard/MyCard";
import {Col, Collapse, Row, theme as configTheme} from "antd";
import {MyButton, MyImage, MyText} from "../../../components";
import HelpImage from "../../../assets/images/tasks/help-image.svg";
import {MyAliasToken} from "../../../assets/themes/defaultTheme";
import {CollapseArrow, DifficultEasy, DifficultHard, DifficultMedium} from "../../../assets";
import {StyledTasksListContainer} from "./index.styles";
import {generatePath, useNavigate} from "react-router-dom";
import {ROUTE_PATH} from "../../../constants";

const {useToken} = configTheme
export const TaskIdPage = () => {

  const navigate = useNavigate();

  //@ts-ignore
  const {token}: { token: MyAliasToken } = useToken();

  const handleClickPlan = useCallback((type: string, obj: any) => {
    const path = {
      pathname: generatePath(ROUTE_PATH.CURRENT_TASK, {taskId: "1", currentTask: obj.key}),
    }

    navigate(path);
  }, [navigate])

  const mock = [
    {
      key: 1,
      label: "Название задачи",
      difficult: 2,
      children: <p>content</p>,
      header: () => (<div>Hello world!</div>)
    },
    {
      key: 2,
      label: "Термостат",
      difficult: 1,
      children: <div>
        <MyText lineHeight="24px" size={16}>Компания продающая котлы отопления наняла вас в качестве тестировщика
          оборудования. Ваша первая задача протетировать термостат котла отопления</MyText>
        <Row justify="space-between" align="bottom" style={{marginTop: 16}}>
          <Col>
            <Row style={{marginBottom: 8}}>
              <MyText lineHeight="24px" size={16} color={token.subtitle}>Среднее время прохождения: </MyText>
              <MyText lineHeight="24px" size={16} style={{marginLeft: 8}}>{" "} 60 минут</MyText>
            </Row>
            <Row>
              <MyText lineHeight="24px" size={16} color={token.subtitle}>Количество решивших задачу: </MyText>
              <MyText lineHeight="24px" size={16} style={{marginLeft: 8}}>{" "} 112</MyText>
            </Row>
          </Col>
          <MyButton height={40} title="Перейти" ghost type="primary" size="large" onClick={() => handleClickPlan('', {key: 1})}/>
        </Row>
      </div>
    }
  ]

  const handleRenderDifficult = (difficult: number) => {
    const obj = {
      1: <DifficultEasy/>,
      2: <DifficultMedium/>,
      3: <DifficultHard/>
    }

    //@ts-ignore
    return obj[difficult]
  }

  return (
    <>
      <MyBreadcrumb title="Техники тест дизайна" isBack/>

      <StyledTasksListContainer style={{padding: 16}} gutter={16}>
        <Col span={18}>
          <MyCard style={{padding: 16}}>
            {/*@ts-ignore*/}
            <Collapse expandIconPosition="end" defaultActiveKey={['2']} expandIcon={(panelProps) => <CollapseArrow {...panelProps} />}
                      items={mock.map((mockItem: any) => ({
                        id: mockItem.key,
                        label: <Row align="middle">{handleRenderDifficult(mockItem.difficult)}
                          <MyText size={18} lineHeight="24px" color={token.titleText}>{mockItem.label}</MyText></Row>,
                        children: mockItem.children
                      }))}/>
          </MyCard>
        </Col>
        <Col span={6}>
          <MyCard>
            <Row style={{flexDirection: 'column'}} align="middle" justify="center">
              <MyText size={24} weight={500} lineHeight={"32px"} style={{marginTop: 24, marginBottom: 30}}>Возникли
                вопросы?</MyText>
              <MyImage src={HelpImage} width={268} height={268}/>
              <MyButton style={{marginTop: 30, marginBottom: 40, width: 154}} size="large" ghost type="primary">Написать
                нам</MyButton>
            </Row>
          </MyCard>
        </Col>
      </StyledTasksListContainer>
    </>
  )
}