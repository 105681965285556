import axios from 'axios';
import { message } from "antd";
import {CANCEL} from "redux-saga";
import {Cookies} from "react-cookie";
import {COOKIES_ENUM, LANG} from "../constants";

const cookies = new Cookies()

const cancelTokenSource = axios.CancelToken.source();

// Массив для хранения истории запросов
let requestHistory = [] as any;

let promise = axios.create({
  baseURL: process.env.REACT_APP_URL,
  headers: {
    accept: 'application/json, text/plain, */*',
  },
  cancelToken: cancelTokenSource.token,
  params: {
    debug: process.env.REACT_APP_ENV === 'production' ? null : true
  }
})

//@ts-ignore
promise[CANCEL] = () => cancelTokenSource.cancel();

const axiosConfig = promise

const resetLogin = () => {
  // todo: вернуть, когда апишки перестанут кидать ошибки
  // cookies.remove(COOKIES_ENUM.SCOPE)
  // cookies.remove(COOKIES_ENUM.JWT)

  setTimeout(() => {
    window.location.reload()
  }, 2000)
}

axiosConfig.interceptors.request.use(
  async (config) => {
    let token = cookies.get(COOKIES_ENUM.JWT)
    let lang = cookies.get(COOKIES_ENUM.LANG) || LANG.RU

    if(token){
      config.headers["Authorization"] = `Bearer ${token}`
      config.headers["Accept-Language"] = lang;
      // config.headers["Cache-Control"] = "no-cache";
      // config.headers["Access-Control-Allow-Origin"] = "*";
      // config.withCredentials = true;
    }
    requestHistory.push(config);

    return config
  },
  error => {
    // resetLogin()
    return Promise.reject(error)
  }
)

axiosConfig.interceptors.response.use(
  async (config) => {
    return config
  },
  error => {
    message.error(error.response.data.message)

    if(process.env.REACT_APP_ENV !== 'local'){
      if(error.response.status == '401'){
        resetLogin()
      }
    }

    return Promise.reject(error)
  }
)

export const resendRequests = () => {
  console.log('requestHistory', requestHistory)
  window.location.reload()

// Функция для повторной отправки запросов

  // return Promise.all(requestHistory.map((config: any) => {
  //   return axiosConfig.request(config);
  // })).then(requestHistory.splice(0, requestHistory.length));
}

export default axiosConfig