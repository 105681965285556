import React from 'react';
import logo from './logo.svg';
import './styles/index.scss'
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import {CookiesProvider} from "react-cookie";
import {Provider} from "react-redux";
import {store} from "./store";
import {ROUTES_CONFIG} from "./config";
import {AuthProvider, LangProvider, ThemeProvider} from "./hooks";

function App() {

  const router = createBrowserRouter(ROUTES_CONFIG)


  return (
    <CookiesProvider>
      <Provider store={store}>
        <AuthProvider>
          <ThemeProvider>
            <LangProvider>
              <RouterProvider router={router} />
            </LangProvider>
          </ThemeProvider>
        </AuthProvider>
      </Provider>
    </CookiesProvider>
  );
}

export default App;
