import React from "react";
import {Col, Collapse, Row, theme as configTheme,} from "antd";
import {MainPage, MenuItem} from "./index.styles";
import {MyButton, MyCard, MyCollapse, MyImage, MyText} from "../../components";
import {useAuth} from "../../hooks";
import {Footer} from "../../layout";
import {ROUTE_PATH} from "../../constants";
import {MyAliasToken} from "../../assets/themes/defaultTheme";

import LeftImage from '../../assets/images/main/left-img.svg'
import RightImage from '../../assets/images/main/right-img.svg'

import {CollapseArrow} from "../../assets";



const {useToken} = configTheme
export const Main = () => {
  //@ts-ignore
  const {token}: { token: MyAliasToken } = useToken();

  const mock = [
    {
      key: 1,
      label: "Для этого можно использовать тренажеры, приложения с открытым исходным кодом, реальные приложения, а также специальные сайты для фрилансеров-тестировщиков.",
      children: <MyText>Для этого можно использовать тренажеры, приложения с открытым исходным кодом, реальные приложения, а также специальные сайты для фрилансеров-тестировщиков.</MyText>
    },
    {
      key: 2,
      label: "Как часто Test Pro проводит практические занятия?",
      children: <MyText>Для этого можно использовать тренажеры, приложения с открытым исходным кодом, реальные приложения, а также специальные сайты для фрилансеров-тестировщиков.</MyText>
    },
    {
      key: 3,
      label: "Как часто Test Pro проводит практические занятия?",
      children: <MyText>Для этого можно использовать тренажеры, приложения с открытым исходным кодом, реальные приложения, а также специальные сайты для фрилансеров-тестировщиков.</MyText>
    },
    {
      key: 4,
      label: "Куда обратиться по вопросам работы на платформе?",
      children: <MyText>Для этого можно использовать тренажеры, приложения с открытым исходным кодом, реальные приложения, а также специальные сайты для фрилансеров-тестировщиков.</MyText>
    },
  ]

  return (
    <MainPage>
      <MyCard style={{padding: 40}}>
        <Row>

          <Col span={12}>
            <Col style={{marginBottom: 32}}>
              <MyText weight={700} size={40} lineHeight="48px" color={token.titleText}>Практика для QA
                инженеров</MyText>
            </Col>
            <Col style={{marginBottom: 24}}>
              <MyText size={16} lineHeight="24px" color={token.subtitle}>
                <MyText weight={700} lineHeight="24px" color={token.subtitle}>Наша миссия</MyText> — помочь тем, кто
                стремится войти в область информационных технологий, освоить профессию
                тестировщика и набраться практических навыков.
              </MyText>
            </Col>
            <Col style={{marginBottom: 56}}>
              <MyText size={16} lineHeight="24px" color={token.subtitle}>
                Абсолютно верно, теоретическое обучение - это только начало. Существует множество книг и материалов о
                тестировании, но чтобы стать квалифицированным специалистом в области QA, нужно получить практический
                опыт.
              </MyText>
            </Col>

            <MyImage src={LeftImage}/>
          </Col>
          <Col span={12}>
            <MyImage src={RightImage}/>

            <Col style={{marginTop: 48}}>
              <MyText weight={700} size={40} lineHeight="48px" color={token.titleText}>Обучение на этом курсе — как
                симулятор стажировки</MyText>
            </Col>

            <Col style={{marginTop: 48, marginBottom: 32}}>
              <MyText size={16} lineHeight="24px" color={token.subtitle}>
                Мы предоставляем нашим студентам возможность не только учить теорию, но и применять полученные знания на
                практических задачах и проектах, что позволит набраться опыта, приближенного к реальным условиям работы
                QA-специалиста.
              </MyText>
            </Col>

            <MyButton ghost type="primary">Начать обучение</MyButton>
          </Col>
        </Row>
      </MyCard>


      <MyCard style={{padding: 40, marginTop: 56}}>
        <Row justify="center" style={{marginBottom: 32}}>
          <MyText weight={700} size={40} lineHeight="48px" color={token.titleText}>Вопросы и ответы</MyText>
        </Row>

        <MyCollapse expandIconPosition="end" defaultActiveKey={['2']}
                  expandIcon={(panelProps) => <CollapseArrow {...panelProps} />}
                  items={mock}/>
      </MyCard>
    </MainPage>
  )
}