import {MyInputPasswordStyles, MyInputSearchStyles, MyInputStyles, MyTextareaStyles} from "./MyInput.styles";
import {MyInputProps, MyTextareaProps} from "./MyInput.props";
import React from "react";
import {useTheme} from "../../../hooks";

export const MyInput = ({...props}: MyInputProps): React.ReactElement => {
  return (
    <MyInputStyles {...props}/>
  )
}
export const MyPassword = ({...props}: MyInputProps): React.ReactElement => {
  return (
    <MyInputPasswordStyles {...props}/>
  )
}

export const MyTextarea = ({...props}: MyTextareaProps): React.ReactElement => {
  return (
    <MyTextareaStyles {...props}/>
  )
}

export const MySearch = ({...props}: MyInputProps): React.ReactElement => {
  const {themeConfig} = useTheme()
  const themes = {
    buttonBackgroundColor: themeConfig?.components?.Input?.colorBgContainer,
    iconColor: '#838383',
    borderColor: themeConfig?.components?.Input?.colorBorder
  }
  return (
    <MyInputSearchStyles {...props} theme={themes}/>
  )
}