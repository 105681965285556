import {useDispatch} from "react-redux";
import {Cookies} from "react-cookie";
import {createContext, useContext, useEffect, useState} from "react";
import {COOKIES_ENUM} from "../constants";


export interface ILogin {
  login: string,
  password: string
}
export interface IAuth {
  isAuth: boolean
  handleLogin: (values:ILogin) => Promise<void>
  handleLogout: () => Promise<void>
}

const cookies = new Cookies();

const initialState: IAuth = {
  isAuth: false,
  handleLogin: (values) => new Promise(() => {
  }),
  handleLogout: () => new Promise(() => {
  })
}

const AuthContext = createContext<IAuth>(initialState);

export const setTokenCookie = (token: string | null) => {
  cookies.set(COOKIES_ENUM.JWT, token)
}

export const AuthProvider = ({children}: any) => {
  const dispatch = useDispatch();

  const [isAuth, setIsAuth] = useState<boolean>(!!cookies.get(COOKIES_ENUM.JWT));

  useEffect(() => {
    const cookiesHandler = (options: any) => {
      if (options.name === COOKIES_ENUM.JWT) {
        if (options.value !== undefined) {
          setIsAuth(true)
        } else {
          setIsAuth(false)
        }
      }
    }

    cookies.addChangeListener((options) => {
      cookiesHandler(options)
    })

    return () => {
      cookies.removeChangeListener((options) => {
        cookiesHandler(options)
      })
    }
  }, [])

  const handleLogin = async (values: any) => {
    setIsAuth(true)
    // dispatch(fetchAuth(values))
    // handleSetJwt(values)
  };

  const handleSetJwt = (token: string | null) => {
    setTokenCookie(token)
    setIsAuth(true)
  }

  const handleLogout = async () => {
    setIsAuth(false);

    cookies.remove(COOKIES_ENUM.JWT)
  };

  return (
    <AuthContext.Provider
      value={{isAuth, handleLogin, handleLogout}}>
      {children}
    </AuthContext.Provider>
  );
}

export const useAuth = () => useContext(AuthContext)