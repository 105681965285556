import styled from "styled-components";
import {Row} from "antd";


export const StyledTasksListContainer = styled(Row)`
  .ant-collapse-header {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background: #fff;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    align-items: center !important;

    .ant-collapse-arrow {
      svg {
        transform: rotate(180deg) !important;
      }
    }
  }

  .ant-collapse-item-active {
    .ant-collapse-header {
      background: var(--c-table-header);

      .ant-collapse-arrow {
        svg {
          transform: rotate(360deg) !important;
        }
      }
    }
  }
  `