import {AliasToken} from "antd/es/theme/interface";

export interface MyAliasToken extends AliasToken {
  primary: string,
  hover: string,
  circleMenu: string,
  lightGreen: string,
  lightHover: string,
  titleText: string,
  subtitle: string,
  icon: string,
  disabled: string,
  border: string,
  line: string,
  dividers: string,
  bg: string,
  tableHeader: string,
  link: string,
  success: string,
  error: string,
  warning: string,
  notification: string,
}

export const DefaultTokenTheme: Partial<MyAliasToken> = {
  primary: '#4EAF51',
  hover: '#71BF74',
  circleMenu: '#83C785',
  lightGreen: '#A0D4A1',
  lightHover: '#E6F3E5',
  titleText: '#1C1C1E',
  subtitle: '#49494D',
  icon: '#8C8C8C',
  disabled: '#BFBFBF',
  border: '#D9D9D9',
  line: '#E8E8E8',
  dividers: '#F0F0F0',
  bg: '#F5F5F5',
  tableHeader: '#FAFAFA',
  link: '#1890FF',
  success: '#52C41A',
  error: '#F5222D',
  warning: '#FAAD14',
  notification: '#FADB14',
}