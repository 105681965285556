import styled from "styled-components";
import {Col, Row} from "antd";

export const StyledTasksPageContainer = styled(Row)`
  margin: 16px;
`

export const StyledCardContainer = styled.div`
  padding: 8px 16px;
  background: #fff;
  box-shadow: 0 2px 8px 0 #00000026;
  border-radius: 4px;
  transition: 200ms;
  
  &:hover{
    cursor: pointer;
    
    .title{
      color: var(--c-primary) !important;
      transition: 200ms;
    }
  }
`