import styled from "styled-components";
import {Col, Row} from "antd";

export const AuthPage = styled(Row)`
  height: 100%;
  
  .ant{
    &-form-item-label label{
      font-size: 14px;
      line-height: 20px;
      font-weight: 500;
      color: var(--c-icon);
    }
  }
`
export const LeftCol = styled(Col)`
  background-color: #F5F5F5;
  display: flex;
  justify-content: center;
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: contain;
`

export const RightCol = styled(Col)`
  padding: 64px;
  background-color: #fff;
`