import styled from "styled-components";
import {Layout} from "antd";
import {HeaderProps} from "./Header.props";
import {Link} from "react-router-dom";

export const StyledHeader = styled(Layout.Header)<HeaderProps>`
  padding-inline: 0;
  height: 56px;
  position: relative;
  z-index: 5;
  background: #fff; //#fff 
  border-bottom: 1px solid var(--c-line); //(--c-line)

  .logo-text {
    font-weight: 500;
    color: #fff;
    font-size: 14px;
  }

  span {
    display: block;
  }

  .ant-row {
    line-height: 48px;
  }

  .profile {
    display: flex;
    align-items: center;
    gap: 8px;
  }
`

export const MenuItem = styled(Link)`
  padding: 8px;
  border-radius: 4px;
  transition: all 0.2s;

  &.active {
    background-color: var(--c-light-hover);

    .img {
      background-color: var(--c-dark-press) !important;
      transition: all 0.5s;
    }

    .text {
      color: var(--c-dark-press) !important;
    }
  }
  
  &:hover {
    background-color: var(--c-light-hover);

    .img {
      background-color: var(--c-dark-press) !important;
      transition: all 0.5s;
    }

    .text {
      color: var(--c-dark-press) !important;
    }
  }

  .text {
    transition: all 0.2s;
  }

  .img {
    transition: all 0.5s;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background-color: var(--c-circle-menu);
    padding: 8px
  }
`