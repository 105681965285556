import React, {useEffect} from "react";
import {FloatButton, Layout} from "antd";
import {Navigate, useLocation, useOutlet} from "react-router-dom";
import {Header} from "../Header/Header";
import {useAuth, useLang, useTheme} from "../../hooks";
import {StyledLayout} from "./Layout.styles";
import {ROUTE_PATH} from "../../constants";
import {useDispatch} from "react-redux";
import {Cookies} from "react-cookie";
import {Sider} from "../Sider/Sider";
// import {fetchUserProfileAction, getMenuAction} from "../../store/pages/profile/actions";
// import {getCtpAction, getPeriodAction} from "../../store/common/actions";
// import {fetchScheduleAction} from "../../store/pages/schedule/actions";

const cookies = new Cookies()
export const ProtectedLayout = (): React.ReactElement => {
  const outlet = useOutlet();
  const dispatch = useDispatch();
  const {isAuth} = useAuth()
  const {theme} = useTheme()
  const {lang} = useLang()
  const location = useLocation()

  // const checkPath = routes.some(item => item.split('/')[1] === location.pathname.split('/')[1])

  useEffect(() => {
    if (isAuth) {
      // dispatch(fetchUserProfileAction())
      // dispatch(getMenuAction())
      //
      // dispatch(getCtpAction())
      // @ts-ignore
      // dispatch(fetchScheduleAction())
      // dispatch(getPeriodAction())
    }
  }, [])

  if (!isAuth) {
    return <Navigate to={ROUTE_PATH.AUTH} replace={true}/>
  }

  // if (!checkPath) {
  //   return <Navigate to={menu[0]?.path} replace={true}/>
  // }


  return (
    <StyledLayout className={`theme-${theme}`} lang={lang}>
      <Layout style={{maxHeight: "100vh", maxWidth: "100vw", overflow: 'hidden'}}>
        <Sider/>
        <Layout.Content style={{overflowY: 'scroll'}}>
          <Header/>
          {/*<div style={{maxHeight: 'calc(100vh - 200px)', minHeight: 'calc(100vh - 200px)', overflowY: 'scroll'}}>*/}
            {outlet}
          {/*</div>*/}
          <FloatButton.BackTop/>
        </Layout.Content>
      </Layout>
    </StyledLayout>
  )
}